<template>
  <div class="login">
    <div class="logo">
      <img src="./images/logo.png">
      车秒净
    </div>
    <van-form @Submit="Submit">
      <van-cell-group inset>
        <van-field
          v-model="userInfo.account"
          name="pattern"
          label="手机号"
          label-width="60px"
          placeholder="请输入手机号"
          :rules="[{ pattern:phoneRegular, message: '请输入正确的手机号' }]"
        />
        <van-field
          v-model="userInfo.password"
          name="validator"
          type="password"
          label="密码"
          label-width="60px"
          placeholder="请输入密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          登陆
        </van-button>
      </div>
    </van-form>
    <p class="register" @click="register" v-if="false">注册账号</p>
    <van-checkbox :label-disabled="true" v-model="checked">已阅读并同意<span @click="agreement">《服务协议》</span>和<span @click="policy">《隐私政策》</span></van-checkbox>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router'
  import { reactive,ref } from 'vue'
  import { reqLogin } from '../../API/login'
  import { showToast } from 'vant'

  const $router = useRouter()
  const userInfo = reactive({
    account:'',
    password:''
  })

  const checked = ref(false);

  //手机号验证正则
  const phoneRegular = ref(/^1[3456789]\d{9}$/)

  //登陆
  const Submit = async () =>{
    if(checked.value){
      const result = await reqLogin(userInfo)
      showToast(result.msg)
      if(result.code == 1){
        localStorage.setItem('TOKEN',result.data.userinfo.token)
        if(localStorage.getItem('parking_id')){
          $router.push({
            path:'/',
            query:{
              parking_id:localStorage.getItem('parking_id')
            }
          })
        }else{
          $router.push('/home')
        }
      }
    }else{
      showToast('阅读并同意服务协议和隐私政策')
    }
    
  }

  //注册
  const register = () => {
    $router.push('/register')
  }

  //服务协议
  const agreement = () => {
    $router.push('/user/agreement')
  }

  //隐私政策
  const policy = () => {
    $router.push('/user/policy')
  }
</script>

<style lang="scss" scoped>
  .login{
    width: 100%;
    height: 100vh;
    background-color: #f7f8fa;
    font-size: 16px;
    .logo{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 50px;
      img{
        width: 86px;
        height: 68px;
        margin-bottom: 10px;
      }
    }
    .van-form{
      margin-top: 50px;
      .van-cell{
        font-size: 16px;
        height: 60px;
      }
    }
    .register{
      padding: 0 15px;
      text-align: right;
    }
    .van-checkbox{
      padding: 0 15px;
      margin-top: 20px;
      span{
        color: #9a7fff;
      }
    }
  }
</style>